export const environment = {
    production: true,
    dev: true,
    dialogBoxHeight: '80%',
    dialogBoxWidth: '50%',
    dialogBoxPanelClass: 'custom-dialog-box',
    secretKey: 'KAVITHA_LIBRUMS',
    raman_token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYwMDAyNDc3OTkwZGUzMzY0OTk0YmJkYyIsImlhdCI6MTYyMjYyNTYwMiwiZXhwIjoxNjU0MTYxNjAyfQ.oPtQ_Xh96e_OX7aYWtuOpQpjq1j9u6KQCEijTUUU-f4',
    default_chapter_amount: 3,


    baseUrl: 'https://api-staging-v1.librums.com/api',
    baseAdminUrl: 'https://api-staging-v1.librums.com/admin',
    imageBaseUrl: 'https://librums-staging.s3.amazonaws.com/',
    uploadImageBaseUrl: 'https://api-staging-v1.librums.com/api/uploadImage/',
  
    googleTestingAClientId:
      '88453083355-87hpr81hchac3tqfq9t08ve7vhjpq9h5.apps.googleusercontent.com',
    googleTestingClientSecret: 'yJY3knxPD1f1gvMLnAxJq0nu',
    PAYPAL_CLIENT_ID:
      'AbqXHSZMCf66UNLSQfEqqKoWth2rmJk4MCb-ZcYzLsbSaQAPYqXgzwVAncsnnw0HuW0DDlduEaE59GLa',
    sketchBookId: '614335984cc7a398313b507e',
    videoBookId: '614335dd550ec4e995161a11',
    firebaseConfig: {
      apiKey: "AIzaSyDhqYnnWHmsPIp0qpzF4TONvR-p-AbHsDw",
      authDomain: "librums-3c2f8.firebaseapp.com",
      databaseURL: "https://librums-3c2f8.firebaseio.com",
      projectId: "librums-3c2f8",
      storageBucket: "librums-3c2f8.appspot.com",
      messagingSenderId: "786516371497",
      appId: "1:786516371497:web:d52854af3cc7ae5b1a1438",
      measurementId: "G-4BQFQ8P8JC"
    }
    
  };
  